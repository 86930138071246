import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'
import Layout2 from '../views/layout/Layout2'
/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  {
    path: '',
    component: Layout,
    redirect: '/welcome',
    children: [{
      path: 'welcome',
      name: 'welcome',
      component: () => import('@/views/home/welcome'),
      meta: { title: '欢迎页', icon: 'home' }
    }]
  }
]

export const asyncRouterMap = [
  // 用户管理
  {
    path: '/sms',
    component: Layout,
    redirect: '/sms/userList',
    name: 'sms',
    meta: { title: '用户管理', icon: 'sms' },
    children: [
      {
        path: 'userList',
        name: 'userList',
        component: () => import('@/views/sms/userList/index'),
        meta: { title: '用户列表', icon: 'user-list' },
      },
      {
        path: 'enterpriseCertification',
        name: 'enterpriseCertification',
        component: () => import('@/views/sms/userList/enterpriseCertification.vue'),
        meta: { title: '提交企业认证', icon: 'user-list' },
        hidden: true
      },
      {
        path: 'updateUser',
        name: 'updateUser',
        component: () => import('@/views/sms/userList/update.vue'),
        meta: { title: '修改用户信息', icon: 'user-list' },
        hidden: true
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/views/sms/userList/index'),
        meta: { title: '通知' }
      },
      {
        path: 'loginRecordList',
        name: 'loginRecordList',
        component: () => import('@/views/sms/userList/loginRecordList.vue'),
        meta: { title: '用户登录记录列表', icon: 'user-list' },
        hidden: true
      },
      {
        path: 'searchRecordList',
        name: 'searchRecordList',
        component: () => import('@/views/sms/userList/searchRecordList.vue'),
        meta: { title: '用户搜索记录列表', icon: 'user-list' },
        hidden: true
      },
    ]
  },
  // 权限管理
  {
    path: '/ums',
    component: Layout,
    redirect: '/ums/admin',
    name: 'ums',
    meta: { title: '权限', icon: 'ums' },
    children: [
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/ums/admin/index'),
        meta: { title: '用户列表', icon: 'ums-admin' }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/ums/role/index'),
        meta: { title: '角色列表', icon: 'ums-role' }
      },
      {
        path: 'allocMenu',
        name: 'allocMenu',
        component: () => import('@/views/ums/role/allocMenu'),
        meta: { title: '分配菜单' },
        hidden: true
      },
      {
        path: 'allocResource',
        name: 'allocResource',
        component: () => import('@/views/ums/role/allocResource'),
        meta: { title: '分配资源' },
        hidden: true
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import('@/views/ums/menu/index'),
        meta: { title: '菜单列表', icon: 'ums-menu' }
      },
      {
        path: 'addMenu',
        name: 'addMenu',
        component: () => import('@/views/ums/menu/add'),
        meta: { title: '添加菜单' },
        hidden: true
      },
      {
        path: 'updateMenu',
        name: 'updateMenu',
        component: () => import('@/views/ums/menu/update'),
        meta: { title: '修改菜单' },
        hidden: true
      },
      {
        path: 'resource',
        name: 'resource',
        component: () => import('@/views/ums/resource/index'),
        meta: { title: '资源列表', icon: 'ums-resource' }
      },
      {
        path: 'resourceCategory',
        name: 'resourceCategory',
        component: () => import('@/views/ums/resource/categoryList'),
        meta: { title: '资源分类' },
        hidden: true
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        component: () => import('@/views/ums/dictionary/index'),
        meta: { title: '字典管理', icon: 'dictionary' }
      },
      {
        path: 'bannedList',
        name: 'bannedList',
        component: () => import('@/views/ums/bannedList/index'),
        meta: { title: '封禁列表', icon: 'banned' }
      },
      {
        path: 'securityPolicy',
        name: 'securityPolicy',
        component: () => import('@/views/ums/bannedList/securityPolicy'),
        meta: { title: '安全策略' },
        hidden: true
      },
      {
        path: 'logList',
        name: 'logList',
        component: () => import('@/views/ums/logList/index'),
        meta: { title: '封禁列表', icon: 'log-list' }
      },
    ]
  },
  // 农业商城管理
  {
    path: '/agrochemicalMall',
    component: Layout,
    redirect: '/agrochemicalMall/mallInformation',
    name: 'agrochemicalMall',
    meta: { title: '农业商城管理', icon: 'agrochemical' },
    children: [
      {
        path: 'mallInformation',
        name: 'mallInformation',
        component: () => import('@/views/agrochemicalMall/information/index.vue'),
        meta: { title: '资讯列表', icon: 'cms-information' }
      },
      {
        path: 'addInformation',
        name: 'addInformation',
        component: () => import('@/views/agrochemicalMall/information/add.vue'),
        meta: { title: '添加资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateInformation',
        name: 'updateInformation',
        component: () => import('@/views/agrochemicalMall/information/update.vue'),
        meta: { title: '修改资讯', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'classification',
        name: 'classification',
        component: () => import('@/views/agrochemicalMall/information/classification_management.vue'),
        meta: { title: '分类管理', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallUserList',
        name: 'mallUserList',
        component: () => import('@/views/agrochemicalMall/userList/index.vue'),
        meta: { title: '用户列表', icon: 'user_list' }
      },
      {
        path: 'mallTeamList',
        name: 'mallTeamList',
        component: () => import('@/views/agrochemicalMall/userList/team_management.vue'),
        meta: { title: '团队列表', icon: 'user_list' },
        hidden: true
      },
      {
        path: 'mallIntegralList',
        name: 'mallIntegralList',
        component: () => import('@/views/agrochemicalMall/userList/integralList.vue'),
        meta: { title: '积分统计列表', icon: 'user_list' },
        hidden: true
      },
      {
        path: 'mallOrder',
        name: 'mallOrder',
        component: () => import('@/views/agrochemicalMall/order/index.vue'),
        meta: { title: '订单列表', icon: 'order' }
      },
      {
        path: 'mallOrderDetail',
        name: 'mallOrderDetail',
        component: () => import('@/views/agrochemicalMall/order/orderDetail.vue'),
        meta: { title: '订单详情', icon: 'order' },
        hidden: true
      },
      {
        path: 'mallOrderRefund',
        name: 'mallOrderRefund',
        component: () => import('@/views/agrochemicalMall/orderRefund/index.vue'),
        meta: { title: '退款申请列表', icon: 'orderRefund' }
      },
      {
        path: 'mallProduct',
        name: 'mallProduct',
        component: () => import('@/views/agrochemicalMall/product/index.vue'),
        meta: { title: '商品列表', icon: 'product' }
      },
      {
        path: 'mallProductGiftList',
        name: 'mallProductGiftList',
        component: () => import('@/views/agrochemicalMall/product/giftList.vue'),
        meta: { title: '赠品列表', icon: 'product' },
        hidden: true
      },
      {
        path: 'mallProductSkuList',
        name: 'mallProductSkuList',
        component: () => import('@/views/agrochemicalMall/product/skuList.vue'),
        meta: { title: 'sku列表', icon: 'product' },
        hidden: true
      },
      {
        path: 'addMallProduct',
        name: 'addMallProduct',
        component: () => import('@/views/agrochemicalMall/product/add.vue'),
        meta: { title: '添加商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallProduct',
        name: 'updateMallProduct',
        component: () => import('@/views/agrochemicalMall/product/update.vue'),
        meta: { title: '修改商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallProductClassification',
        name: 'mallProductClassification',
        component: () => import('@/views/agrochemicalMall/product/classification_management.vue'),
        meta: { title: '商品分类管理', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallProductRecyclingList',
        name: 'mallProductRecyclingList',
        component: () => import('@/views/agrochemicalMall/product/recyclingList.vue'),
        meta: { title: '商品回收列表', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallRecommend',
        name: 'mallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/index.vue'),
        meta: { title: '推荐位管理', icon: 'recommend' }
      },
      {
        path: 'addMallRecommend',
        name: 'addMallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/add.vue'),
        meta: { title: '添加推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallRecommend',
        name: 'updateMallRecommend',
        component: () => import('@/views/agrochemicalMall/recommend/update.vue'),
        meta: { title: '修改推荐', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallService',
        name: 'mallService',
        component: () => import('@/views/agrochemicalMall/IM/index.vue'),
        meta: { title: '客服中心', icon: 'im' }
      },
      {
        path: 'mallAccess',
        name: 'mallAccess',
        component: () => import('@/views/agrochemicalMall/access/index.vue'),
        meta: { title: '访问统计', icon: 'order' }
      },
      {
        path: 'mallStatistics',
        name: 'mallStatistics',
        component: () => import('@/views/agrochemicalMall/access/statistics.vue'),
        meta: { title: '访问商品统计', icon: 'order' },
        hidden: true
      },
      {
        path: '/agrochemicalMall/mallMarketing',
        name: 'mallMarketing',
        component: Layout2,
        meta: { title: '营销管理', icon: 'tdk-management' },
        children: [
          {
            id: 182,
            path: 'mallCoupon',
            name: 'mallCoupon',
            component: () => import('@/views/agrochemicalMall/marketing/coupon/index.vue'),
            meta: { title: '优惠券管理', icon: 'tdk-management' },
          },
          {
            path: 'addMallCoupon',
            name: 'addMallCoupon',
            component: () => import('@/views/agrochemicalMall/marketing/coupon/add.vue'),
            meta: { title: '添加优惠券', icon: 'tdk-management' },
            hidden: true
          },
          {
            path: 'updateMallCoupon',
            name: 'updateMallCoupon',
            component: () => import('@/views/agrochemicalMall/marketing/coupon/update.vue'),
            meta: { title: '更改优惠券', icon: 'tdk-management' },
            hidden: true
          },
          {
            path: 'mallCouponAssociationList',
            name: 'mallCouponAssociationList',
            component: () => import('@/views/agrochemicalMall/marketing/coupon/skuList.vue'),
            meta: { title: '优惠券关联列表', icon: 'tdk-management' },
            hidden: true
          },
          {
            path: 'mallCouponCollectionList',
            name: 'mallCouponCollectionList',
            component: () => import('@/views/agrochemicalMall/marketing/coupon/collectionList.vue'),
            meta: { title: '优惠券领取列表', icon: 'tdk-management' },
            hidden: true
          },
          {
            id: 183,
            path: 'mallPackage',
            name: 'mallPackage',
            component: () => import('@/views/agrochemicalMall/marketing/package/index.vue'),
            meta: { title: '精选套餐管理', icon: 'tdk-management' },
          },
          {
            path: 'addMallPackage',
            name: 'addMallPackage',
            component: () => import('@/views/agrochemicalMall/marketing/package/add.vue'),
            meta: { title: '添加精选套餐', icon: 'tdk-management' },
            hidden: true
          },
          {
            path: 'updateMallPackage',
            name: 'updateMallPackage',
            component: () => import('@/views/agrochemicalMall/marketing/package/update.vue'),
            meta: { title: '更改精选套餐', icon: 'tdk-management' },
            hidden: true
          },
          {
            path: 'mallPackageAssociationList',
            name: 'mallPackageAssociationList',
            component: () => import('@/views/agrochemicalMall/marketing/package/skuList.vue'),
            meta: { title: '精选套餐关联列表', icon: 'tdk-management' },
            hidden: true
          },
          {
            id: 184,
            path: 'mallSeck',
            name: 'mallSeck',
            component: () => import('@/views/agrochemicalMall/marketing/seck/index.vue'),
            meta: { title: '秒杀管理', icon: 'tdk-management' },
          },
        ]
      },
      {
        path: 'mallFreight',
        name: 'mallFreight',
        component: () => import('@/views/agrochemicalMall/freight/index.vue'),
        meta: { title: '运费模板管理', icon: 'freight' }
      },
      {
        path: 'addMallFreight',
        name: 'addMallFreight',
        component: () => import('@/views/agrochemicalMall/freight/add.vue'),
        meta: { title: '添加运费模板', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateMallFreight',
        name: 'updateMallFreight',
        component: () => import('@/views/agrochemicalMall/freight/update.vue'),
        meta: { title: '修改运费模板', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'mallNoteList',
        name: 'mallNoteList',
        component: () => import('@/views/agrochemicalMall/note/index.vue'),
        meta: { title: '园艺笔记列表', icon: 'cms-information' }
      },
      {
        path: 'addNote',
        name: 'addNote',
        component: () => import('@/views/agrochemicalMall/note/add.vue'),
        meta: { title: '添加园艺笔记', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateNote',
        name: 'updateNote',
        component: () => import('@/views/agrochemicalMall/note/update.vue'),
        meta: { title: '修改园艺笔记', icon: 'product-add' },
        hidden: true
      },
    ]
  },
  //评价列表
  {
    path: '/evaluation',
    component: Layout,
    redirect: '/evaluation/evaluationList',
    name: 'evaluation',
    meta: { title: '评价列表', icon: 'assess' },
    children: [
      {
        path: 'evaluationList',
        name: 'evaluationList',
        component: () => import('@/views/evaluation/index.vue'),
        meta: { title: '评价列表', icon: 'assess' }
      },
    ]
  },
  //分销管理
  {
    path: '/distribution',
    component: Layout,
    redirect: '/distribution/memberList',
    name: 'distribution',
    meta: { title: '分销管理', icon: 'store' },
    children: [
      {
        path: 'memberList',
        name: 'memberList',
        component: () => import('@/views/distribution/member.vue'),
        meta: { title: '分销会员', icon: 'user-list' }
      },
      {
        path: 'distributionSetting',
        name: 'distributionSetting',
        component: () => import('@/views/distribution/setting.vue'),
        meta: { title: '分销设置', icon: 'product' }
      },
      {
        path: 'distributionOrder',
        name: 'distributionOrder',
        component: () => import('@/views/distribution/order/index.vue'),
        meta: { title: '分销订单', icon: 'order' }
      },
      {
        path: 'distributionOrderDetail',
        name: 'distributionOrderDetail',
        component: () => import('@/views/distribution/order/orderDetail.vue'),
        meta: { title: '订单详情', icon: 'order' },
        hidden: true
      },
      {
        path: 'settleList',
        name: 'settleList',
        component: () => import('@/views/distribution/settle.vue'),
        meta: { title: '佣金结算', icon: 'cms-policy' }
      },
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

export default new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

